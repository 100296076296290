<template lang="">
    <div class="wrapper">
        <el-breadcrumb separator="/" class="el-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">业务发展</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/SupplyandDemand' }">行业供求市场</el-breadcrumb-item>
            <el-breadcrumb-item>需求列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="concent">
            <!-- 需求列表 -->
            <section class="section1">
                <div class="chartView clearfix">
                    <div class="top clearfix">
                        <div class="title fl">物资需求</div>
                    </div>
                    <div class="search-box" :class="!isCollapse ? 'expand' : ''">
                        <el-form ref="form" @submit.native.prevent label-width="70px" label-position="left">
                            <el-row :gutter="24">
                                <el-col :span="6">
                                    <el-form-item label="按行业">
                                        <el-select v-model="queryTable.condition.activityType">
                                            <el-option v-for="item in type" :key="item.itemValue" :label="item.itemDesc"
                                                :value="item.itemValue"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="按分类">
                                        <el-select v-model="queryTable.condition.activityStatus">
                                            <el-option v-for="item in state" :key="item.value" :label="item.label"
                                                :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="按时间">
                                        <el-date-picker v-model="dateRange" @change="getTime" type="date"
                                            value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-form-item label="搜索">
                                        <el-input v-model="queryTable.condition.sponsor" placeholder="关键字搜索"
                                            :maxlength="20" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <div class="search-btns">
                                <el-button type="primary" @click="queryTable.query()" >
                                    查询</el-button>
                                <el-button @click="reset()">重置</el-button>
                            </div>
                        </el-form>
                    </div>
                    <div class="sort">
                        <div>
                            <el-button size="small" class="default" @click="defaultSort">发布需求</el-button>
                        </div>
                        <div class="right">
                            共 <span class="n">{{ queryTable.count }}</span> 条<img
                                src="../../assets/img/arrow_left@2x.png" alt="" class="arrow" />
                            <span class="b">{{ queryTable.page }}</span><span class="c">/{{ total }}</span>
                            <img src="../../assets/img/arrow_right@2x.png" alt="" class="arrow" />
                        </div>
                    </div>
                    <el-table :data="queryTable.table">
                        <el-table-column label="标签" prop="tag" align="center"></el-table-column>
                        <el-table-column label="名称" prop="name" align="center" ></el-table-column>
                        <el-table-column label="截止日期" prop="date" align="center"></el-table-column>
                      </el-table>
                    <el-row style="margin-top: 25px">
                        <el-pagination background layout="total, prev, pager, next, jumper"
                            :page-size="queryTable.pageSize" :total="queryTable.count"
                            :current-page.sync="queryTable.page" @current-change="queryTable.search()"></el-pagination>
                    </el-row>
                </div>
            </section>


        </div>
    </div>
</template>
<script>
    import { QueryTable } from '@/assets/js/QueryTable';
    
    export default {
        name: 'activitiesSquare',
        data() {
            return {
                isCollapse: false, // 搜索条件默认收起
                dateRange: [],
                queryTable: new QueryTable('activity', {
                    // activityStatus: '',
                    activityType: '',
                    sponsor: '',
                    activityName: '',
                    createTime: '',
                    endDate: '',
                    orderBy: 1,
                }),
                type: [],
                state: [
                    {
                        value: '1',
                        label: '已报名',
                    },
                    {
                        value: '2',
                        label: '未报名',
                    },
                    {
                        value: '3',
                        label: '不可报名',
                    },
                ],
            };
        },

        created() {
            this.queryTable.search();
            this.$api.getkey({ dictKey: 'activity_type' }).then((res) => {
                this.type = res.result;
            });
            var _data = [{
                tag: '需求1',
                name: '杭州**科技有限公司',
                date: '2021-12-19'
            }, {
                tag: '需求2',
                name: '杭州**科技有限公司',
                date: '2021-12-19'
            }, {
                tag: '需求3',
                name: '杭州**科技有限公司',
                date: '2021-12-19'
            },{
                tag: '需求1',
                name: '杭州**科技有限公司',
                date: '2021-12-19'
            }, {
                tag: '需求2',
                name: '杭州**科技有限公司',
                date: '2021-12-19'
            }, {
                tag: '需求3',
                name: '杭州**科技有限公司',
                date: '2021-12-19'
            },{
                tag: '需求1',
                name: '杭州**科技有限公司',
                date: '2021-12-19'
            }, {
                tag: '需求2',
                name: '杭州**科技有限公司',
                date: '2021-12-19'
            }, {
                tag: '需求3',
                name: '杭州**科技有限公司',
                date: '2021-12-19'
            }]

            this.queryTable.table= _data
        },
        methods: {
            defaultSort() {
                this.$router.push({
                    path: '/SupplyandDemand/demandRelease'
                })
            },
            changeSort() {
                switch (this.queryTable.condition.orderBy) {
                    case 1:
                        this.queryTable.condition.orderBy = 2;
                        break;
                    case 2:
                        this.queryTable.condition.orderBy = 3;
                        break;
                    case 3:
                        this.queryTable.condition.orderBy = 2;
                        break;
                }
                this.queryTable.search();
            },
            reset() {
                this.dateRange = [];
                this.queryTable.reset();
            },
            goDetail(id) {
                this.$router.push({ name: 'ActivityDetail', params: { id } });
            },
            getTime(val) {
                if (val) {
                    this.queryTable.condition.createTime = val[0];
                    this.queryTable.condition.endDate = val[1];
                }else{
                    this.queryTable.condition.createTime ='';
                    this.queryTable.condition.endDate = '';
                }
            },
        },
        computed: {
            total() {
                return Math.ceil(this.queryTable.count / this.queryTable.pageSize);
            },
        },
    };
</script>
<style lang="scss" scoped>
    .fl {
        float: left;
    }

    .fr {
        float: right;
    }

    .tc {
        text-align: center;
    }

    .clearfix::after {
        content: '';
        display: block;
        clear: both;
    }

    .wrapper {
        padding-left: 83px;
        padding-right: 83px;
        padding-bottom: 15px;
        margin: 0 auto;
        min-width: 1366px;

        section {
            margin-bottom: 16px;
        }

    }

    

    .chartView {
        padding: 16px 20px 24px;
        background-color: #fff;
    }

    .chartView .top {
        padding-bottom: 15px;
        border-bottom: 1px solid #dddddd;
        margin-bottom: 16px;
    }

    .chartView .item-box {
        width: calc((100% - 40px) * 0.33333);
        height: 176px;
        border-radius: 0px 0px 4px 4px;
        background-image: linear-gradient(180deg, #f6faff 0%, #e9f4ff 100%);
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-right: 20px;
    }


    .concent .title {
        font-family: 'MicrosoftYaHei-Bold';
        font-size: 26px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        position: relative;
        padding-left: 14px;
    }

    .concent .title:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        left: 0;
    }

    .sort {
        margin-top: 30px;
        display: flex;
        padding-bottom: 15px;
        border-bottom: 1px solid #dddddd;
        justify-content: space-between;
        align-items: center;

        /deep/ .sortImg {
            width: 14px;
            height: 14px;
            vertical-align: middle;
            margin-left: 12px;
        }

        .right {
            font-size: 14px;
            color: #666666;
        }

        .arrow {
            width: 12px;
            height: 12px;
        }

        .arrow:nth-of-type(1) {
            margin-left: 12px;
        }

        .n {
            color: #135694;
        }

        .b {
            color: #333333;
        }

        .c {
            font-size: 12px;
            color: #151515;
            margin-right: 6px;
        }
    }

    .item {
        height: 160px;
        border-bottom: 1px solid #ddd;
        padding: 19.5px 0;
        display: flex;

        .img {
            height: 160px;
            width: 276px;
            cursor: pointer;
            margin-right: 26px;
        }

        .info {
            flex: 1;

            .titles {
                margin-top: 12px;
                font-size: 18px;
                color: #333333;
                font-weight: 600;
                cursor: pointer;
                margin-bottom: 10px;
            }

            .labels {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .label {
                    background: #eaf2f9;
                    border-radius: 34px;
                    height: 28px;
                    line-height: 28px;
                    padding: 0 15px;
                    font-size: 12px;
                    color: #135694;
                    letter-spacing: 0.3px;
                    margin-right: 16px;
                }
            }

            .texts {
                font-size: 12px;
                color: #666666;
                margin-bottom: 8px;
                line-height: 16px;
            }
        }

        .item-right {
            width: 120px;

            .top {
                text-align: right;
                margin-top: 12px;
                color: #666666;

                span {
                    font-size: 18px;
                    color: #151515;
                }
            }

            .rs {
                width: 16px;
                height: 16px;
                margin-right: 6px;
                vertical-align: text-top;
            }

            .btn1 {
                background: #135694;
                border-radius: 4px;
                width: 96px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
                color: #ffffff;
                margin-top: 28px;
                cursor: pointer;
                float: right;
            }

            .btn2 {
                font-size: 14px;
                color: #135694;
                line-height: 19px;
                margin-top: 38px;
                margin-right: 20px;
                cursor: pointer;
                text-align: right;
            }

            .btn3 {
                background: #f7f7f7;
                border: 1px solid #dddddd;
                border-radius: 4px;
                width: 96px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 14px;
                color: #999999;
                margin-top: 28px;
                float: right;
            }
        }
    }
</style>